import '../style/statute.css';
export function Statute() {
    return (
        <div className="content statute-container">
            <h1> Warunki użytkowania </h1>
            <p className="h3">1. Przetwarzanie i Przechowywanie Zdjęć </p>
            <p>Użytkownik zgadza się na przechowywanie przesłanych zdjęć w bazie danych i przetwarzanie w celu świadczenia usług związanych z serwisem oraz rozwoju tych usług.</p>

            <p className="h3">2. Tworzenie Konta</p>
            <p> Tworząc konto w serwisie, użytkownik akceptuje niniejszy regulamin.</p>

            <p className="h3">3. Odpowiedzialność Serwisu </p>
            <p>Strona nie ponosi odpowiedzialności za nielegalne wykorzystanie serwisu przez użytkowników.</p>

            <p className="h3">4. Kontakt</p>
            <p> W przypadku pytań lub wątpliwości dotyczących regulaminu, prosimy o kontakt pod adresem: sortspend@gmail.com.</p>

            <p className="h3">5. Ochrona Danych Osobowych </p>
            <p>Serwis zobowiązuje się do przestrzegania obowiązujących przepisów dotyczących ochrony danych osobowych.</p>

            <p className="h3">6. Zmiany w warunkach użytkowania </p>
            <p>Serwis zastrzega sobie prawo do wprowadzania zmian w warunkach użytkowania. Aktualna wersja regulaminu będzie zawsze dostępna na stronie sortspend.pl/regulamin .</p>

            <p className="h3">7. Akceptacja regulaminu </p>
            <p> Korzystając z serwisu, użytkownik akceptuje postanowienia niniejszego regulaminu.</p>

        </div>
        );
}
