const productCategories = [
    "Inne i przedmioty niesklasyfikowane",
    "Żywność",
    "Napoje",
    "Owoce i warzywa",
    "Mięso i ryby",
    "Nabiał",
    "Produkty zbożowe",
    "Słodycze i przekąski",
    "Napoje alkoholowe",
    "Kosmetyki",
    "Produkty czystości",
    "Odzież",
    "Dom i ogród",
    "Medykamenty"
];

const categoriez = {
    "produkty zbożowe": {
        "category": "produkty spożywcze"
    },
    "nabiał": {
        "category": "produkty spożywcze"
    },
    "owoce i warzywa": {
        "category": "produkty spożywcze"
    },
    "mięso i ryby": {
        "category": "produkty spożywcze"
    },
    "napoje": {
        "category": "produkty spożywcze"
    },
    "napoje alkoholowe": {
        "category": "produkty spożywcze"
    },
    "słodycze i przekąski": {
        "category": "produkty spożywcze"
    },
    "pozostałe produkty spożywcze": {
        "category": "produkty spożywcze"
    },
    "rekreacja": {
        "category": "rozrywka i wypoczynek"
    },
    "hotel": {
        "category": "rozrywka i wypoczynek"
    },
    "subskrypcje": {
        "category": "rozrywka i wypoczynek"
    },
    "bilety na wydarzenia": {
        "category": "rozrywka i wypoczynek"
    },
    "pozostała rozrywka i wypoczynek": {
        "category": "rozrywka i wypoczynek"
    },
    "paliwo": {
        "category": "transport"
    },
    "transport publiczny/prywatny": {
        "category": "transport"
    },
    "opłaty stałe": {
        "category": "podatki i opłaty"
    },
    "podatki": {
        "category": "podatki i opłaty"
    },
    "czynsz": {
        "category": "podatki i opłaty"
    },
    "pozostałe opłaty": {
        "category": "podatki i opłaty"
    },
    "leki": {
        "category": "medykamenty"
    },
    "suplementy": {
        "category": "medykamenty"
    },
    "pozostałe medykamenty": {
        "category": "medykamenty"
    },
    "produkty czystości": {
        "category": "produkty czystości"
    },
    "odzież": {
        "category": "odzież"
    },
    "wyposażenie domu": {
        "category": "dom i ogród"
    },
    "roślinność": {
        "category": "dom i ogród"
    },
    "dekoracje": {
        "category": "dom i ogród"
    },
    "produkty do remontu": {
        "category": "dom i ogród"
    },
    "pozostałe produkty wyposażenia domu i ogrodu": {
        "category": "dom i ogród"
    },
    "rtv": {
        "category": "sprzęt elektroniczny"
    },
    "agd": {
        "category": "sprzęt elektroniczny"
    },
    "pozostałe sprzęty elektroniczne": {
        "category": "sprzęt elektroniczny"
    },
    "perfumy": {
        "category": "kosmetyki"
    },
    "kosmetyki": {
        "category": "kosmetyki"
    },
    "pozostałe produkty piękności": {
        "category": "kosmetyki"
    },
    "inne niesklasyfikowane": {
        "category": "inne niesklasyfikowane"
    }
};

const getCategoryName = (categoryIndex) => {
    if (categoryIndex >= 0 && categoryIndex < productCategories.length) {
        return productCategories[categoryIndex];
    }

    return 'Nieznana kategoria';
};

//main kat{sub:val} => kat{val, subkat{}}
const transformCategories = (backendData) => {
    const transformedData = {};

    Object.entries(backendData).forEach(([category, subcategories]) => {
        const total = Object.values(subcategories).reduce((acc, value) =>  parseFloat(acc +value), 0);
        transformedData[category] = {
            total: parseFloat(total.toFixed(2)),
            subcategories: { ...subcategories },
        };
    });

    return transformedData;
};

export default productCategories;
export { getCategoryName, categoriez, transformCategories };
