import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import {  ArcElement } from 'chart.js'
import Chart from 'chart.js/auto'
import { useSelector } from 'react-redux';
import '../style/css1.css'
import '../style/statistics.css'
import { transformCategories } from '../slices/productCategories.js'
import useLogoutUser from '../slices/logoutSlice';
import { toast } from 'react-toastify';

Chart.register(ArcElement);

export function Statistics2() {

    const [chartMonthlyValueData, setChartMonthlyValueData] = useState();
    const [chartUserValueData, setChartUserValueData] = useState();
    const [nonZeroMonthlyData, setNonZeroMonthlyData] = useState();
    const [nonZeroUserData, setNonZeroUserData] = useState();
    const [testData, setTestData] = useState();
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [chartLevel, setChartLevel] = useState('main');
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const logoutUser = useLogoutUser();
    const { userInfo } = useSelector((state) => state.auth);

    const monthlyChartRef = useRef(null);
    const userChartRef = useRef(null);

    const categoryColorsWithShades = {
        "produkty spożywcze": {
            color: '#36A2EB',
            subcategories: {
                "produkty żbożowe": '#63b8e6',
                "nabiał": '#6fc4e6',
                "owoce i warzywa": '#7ad0e6',
                "mięso i ryby": '#85dce6',
                "napoje": '#90e8e6',
                "napoje alkoholowe": '#9bf4e6',
                "pozostałe produkty spożywcze": '#a6ffe6'
            }
        },
        "rozrywka i wypoczynek": {
            color: '#FF69B4',
            subcategories: {
                "rekreacja": '#ff9ec6',
                "hotel": '#ff9ec6',
                "subskrypcje": '#ffa9cc',
                "bilety na wydarzenia": '#ffb4d2',
                "pozostała rozrywka i wypoczynek": '#ffbfdd'
            }
        },
        "transport": {
            color: '#9966FF',
            subcategories: {
                "paliwo": '#b399ff',
                "transport publiczny/prywatny": '#c299ff'
            }
        },
        "podatki i opłaty": {
            color: '#FF9933',
            subcategories: {
                "opłaty stałe": '#ffb366',
                "podatki": '#ffc266',
                "czynsz": '#ffd066',
                "pozostałe opłaty": '#ffdb66'
            }
        },
        "medykamenty": {
            color: '#00FF00',
            subcategories: {
                "leki": '#80ffaa',
                "suplementy": '#8cffaa',
                "pozostałe medykamenty": '#99ffaa'
            }
        },
        "produkty czystości": {
            color: '#FFD700',
            subcategories: {
                "produkty czystości": '#ffd633'
            }
        },
        "odzież": {
            color: '#800080',
            subcategories: {
                "odzież": '#bf40bf'
            }
        },
        "dom i ogród": {
            color: '#2F4F4F',
            subcategories: {
                "wyposażenie domu": '#4d4d4d',
                "roślinność": '#595959',
                "dekoracje": '#666666',
                "produkty do remontu": '#737373',
                "pozostałe produkty wyposażenia domu i ogrodu": '#808080'
            }
        },
        "sprzęt elektroniczny": {
            color: '#808080',
            subcategories: {
                "rtv": '#a6a6a6',
                "agd": '#b2b2b2',
                "pozostałe sprzęty elektroniczne": '#bfbfbf'
            }
        },
        "kosmetyki": {
            color: '#FFCE56',
            subcategories: {
                "perfumy": '#ffdb80',
                "kosmetyki": '#ffe699',
                "pozostałe produkty piękności": '#fff2b3'
            }
        },
        "inne niesklasyfikowane": {
            color: '#FF6384',
            subcategories: {
                "inne niesklasyfikowane": '#ff8c9d'
            }
        }
    };

    useEffect(() => {
        if (userInfo && userInfo.token) {
            const token = userInfo.token;
            const fetchMonthlyStats = async () => {
                await axios.get(`https://sortspend.pl/api/GET/monthly-stats`, {
                        params: {
                            year: selectedYear,
                            month: selectedMonth
                        },
                        headers: { Authorization: token }
                    })
                    .then(response => {
                        if (response.status == 200) {
                            const rodzaje = response.data;
                            setChartMonthlyValueData(transformCategories(rodzaje));
                        } else if (response.status == 401) {
                            logoutUser();
                        } else {
                            toast.error(response?.data?.message || 'Błąd podczas odczytywania danych');
                        }
                    })
                    .catch(error => {
                        console.error('Błąd podczas pobierania danych:', error);
                    });
            };
            fetchMonthlyStats();
        }
    }, [userInfo, selectedMonth, selectedYear]);

    useEffect(() => {
        if (userInfo && userInfo.token) {
            const token = userInfo.token;
            const fetchUserStats = async () => {
                try {
                    const response = await axios.get(`https://sortspend.pl/api/GET/user-stats`, {
                        headers: { Authorization: token }
                    });
                    if (response.status == 200) {
                        const rodzaje = response.data;
                        setChartUserValueData(transformCategories(rodzaje));
                    }
                    else if (response.status == 401) {
                        logoutUser();
                    }
                } catch (error) {
                    console.error('Błąd podczas pobierania danych:', error);
                }
            };
            fetchUserStats();
        }
    }, [userInfo]);

    const renderListItems = (chartValueData, reverseOrder = false, isLeftList = true) => {
        const listClassName = isLeftList ? 'category-list-left' : 'category-list-right';

        return Object.entries(chartValueData).map(([category, value], index) => (
            <li
                className={`list-group-item ${index % 2 === 0 ? 'even' : 'odd'} ${listClassName}`}
                key={category}
            >
                <span className='left'>{reverseOrder ? value + ' zł' : category}</span>
                <span className='right'>{reverseOrder ? category : value + ' zł'}</span>
            </li>
        ));
    };

  
    const generateChart = (data, chartValueData, chartRef, chartLevel) => {
        if (data && chartValueData) {
            if (chartRef.current) {
                // Dostęp do canvas
                const ctx = chartRef.current.getContext('2d');

                let labels, values;
                let backgroundColors, hoverBackgroundColors;

                if (chartLevel === 'main') {
                    labels = Object.keys(data);
                    values = labels.map(category => data[category].total);
                    backgroundColors = labels.map(label => categoryColorsWithShades[label].color);
                    hoverBackgroundColors = labels.map(label => categoryColorsWithShades[label].color);
                } else {
                    // Znajdź wszystkie niezerowe podkategorie z każdej głównej kategorii
                    const subcategoriesWithData = [];
                    const subcategoriesWithDataValues = [];

                    Object.keys(data).forEach(mainCategory => {
                        const subcategories = data[mainCategory].subcategories;
                        const nonZeroSubcategories = Object.entries(subcategories)
                            .filter(([subcategory, value]) => value > 0)
                            .map(([subcategory]) => subcategory);

                        subcategoriesWithData.push(...nonZeroSubcategories);

                        // Pobierz wartości dla każdej podkategorii
                        const valuesForSubcategories = nonZeroSubcategories.flatMap(subcategory => {
                            return Object.values(data).map(mainCategory => {
                                const value = mainCategory.subcategories[subcategory];
                                return value > 0 && value !== undefined ? value : 0;
                            });
                        });

                        subcategoriesWithDataValues.push(...valuesForSubcategories);
                    });

                    labels = subcategoriesWithData;
                    values = subcategoriesWithDataValues.filter(value => value !== 0);

                    backgroundColors = labels.map(label => {
                        // Wybierz kolor z kategorii, której podkategoria jest częścią
                        const mainCategory = Object.keys(data).find(category =>
                            Object.keys(data[category].subcategories).includes(label)
                        );
                        return categoryColorsWithShades[mainCategory].subcategories[label];
                    });
                    hoverBackgroundColors = backgroundColors;
                }

                // Destroy the previous chart if it exists
                if (chartRef.current.chartInstance) {
                    chartRef.current.chartInstance.destroy();
                }

                // Utwórz instancję wykresu
                const myChart = new Chart(ctx, {
                    type: 'pie',
                    data: {
                        labels: labels,
                        datasets: [{
                            data: values,
                            backgroundColor: backgroundColors,
                            hoverBackgroundColor: hoverBackgroundColors,
                        }],
                    },
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        aspectRatio: 1,
                    },
                });

                chartRef.current.chartInstance = myChart;;
                return () => {
                    if (myChart) {
                        myChart.destroy();
                    }
                };
            }
        }
    };

    useEffect(() => {
        generateChart(nonZeroUserData, chartUserValueData, userChartRef, chartLevel);
    }, [nonZeroUserData, chartUserValueData, userChartRef, chartLevel]);
    useEffect(() => {
        generateChart(nonZeroMonthlyData, chartMonthlyValueData, monthlyChartRef,  chartLevel);
    }, [nonZeroMonthlyData, chartMonthlyValueData, monthlyChartRef, chartLevel]);

    // Użycie funkcji dla drugiego przypadku


    useEffect(() => {
        if (chartMonthlyValueData) {
            const noZeroData = Object.entries(chartMonthlyValueData)
                .filter(([category, { total }]) => total !== 0)
                .reduce((obj, [category, value]) => {
                    obj[category] = value;
                    return obj;
                }, {});
            setNonZeroMonthlyData(noZeroData);
        }
    }, [chartMonthlyValueData]);

    useEffect(() => {
        if (chartUserValueData) {
            const noZeroData = Object.entries(chartUserValueData)
                .filter(([category, { total }]) => total !== 0)
                .reduce((obj, [category, value]) => {
                    obj[category] = value;
                    return obj;
                }, {});
            setNonZeroUserData(noZeroData);
        }
    }, [chartUserValueData]);

    //unused
    const renderListItems2 = (chartValueData, reverseOrder = false, isLeftList = true) => {
        const listClassName = isLeftList ? 'category-list-left' : 'category-list-right';
        if (!chartValueData) {
            return null; 
        }
        return Object.entries(chartValueData).map(([category, subcategories], index) => (
            <li className={`list-group-item ${index % 2 === 0 ? 'even' : 'odd'} ${listClassName}`} key={category}>
                <span className='left'>{reverseOrder ? sumSubcategories(subcategories) + ' zł' : category}</span>
                <span className='right'>{reverseOrder ? category : sumSubcategories(subcategories) + ' zł'}</span>
                {renderSubcategories(subcategories, index)}
            </li>
        ));
    };

    const renderSubcategories = (subcategories, parentIndex) => {
        return (
            <ul className={`subcategories-list list-group list-group-flush`} key={`subcategories-${parentIndex}`}>
                {Object.entries(subcategories).map(([subcategory, value], index) => (
                    <li className={`list-group-item ${index % 2 === 0 ? 'even' : 'odd'}`} key={subcategory}>
                        <span className='left'>{subcategory}</span>
                        <span className='right'>{value + ' zł'}</span>
                    </li>
                ))}
            </ul>
        );
    };

    const sumSubcategories = (subcategories) => {
        return Object.values(subcategories).reduce((sum, value) => sum + value, 0);
    };


    const renderListItemsWithSubcategories = (chartValueData, reverseOrder = false, isLeftList = true) => {
        const listClassName = isLeftList ? 'category-list-left' : 'category-list-right';

        if (!chartValueData) {
            return null;
        }

        return Object.entries(chartValueData).map(([category, { total, subcategories }], index) => (
            <div
                className={`list-group-item ${index % 2 === 0 ? 'even' : 'odd'} ${listClassName}`}
                key={category} onClick={() => toggleCategory(category)}
            >
                <div className="category-name">
                    <span className='left'>
                        {reverseOrder ? total + ' zł' : category}
                    </span>
                    <span className='right'>
                        {reverseOrder ? category : total + ' zł'}
                    </span>
                </div>
                {!isCategoryExpanded(category) && renderSubcategoriesAsParagraphs(subcategories, reverseOrder)}
            </div>
        ));
    };

    const renderSubcategoriesAsParagraphs = (subcategories, reverseOrder) => {
        return (
            <div className="subcategories" style={{ float: reverseOrder ? 'left' : 'none' }}>
                <ul>
                    {Object.entries(subcategories).map(([subcategory, value]) => (
                        <li key={subcategory}>
                            <span className='left'>{reverseOrder ? value + ' zł' : subcategory } &nbsp;</span>
                            <span className='right'>{ reverseOrder? subcategory : value + ' zł'}</span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const toggleCategory = (category) => {
        if (expandedCategories.includes(category)) {
            setExpandedCategories(expandedCategories.filter((item) => item !== category));
        } else {
            setExpandedCategories([...expandedCategories, category]);
        }
    };

    const isCategoryExpanded = (category) => expandedCategories.includes(category);

    const toggleChartCategory = () => {
        setChartLevel(chartLevel === 'main' ? 'sub' : 'main');
    };

    const getToggleButtonText = () => {
        return chartLevel === 'main' ? 'Pokaż podkategorie' : 'Pokaż kategorie';
    };


    return (
        <div className="statistics-content content">
            {userInfo && userInfo.token ? (<>
            <div className="stats-container-left stats-container">
                <h1>Twoje wydatki</h1>
                
                {userChartRef && chartUserValueData ? (
                    <>
                        <div>
                            <Button onClick={toggleChartCategory}>{getToggleButtonText()}</Button>
                        </div>
                        <canvas ref={userChartRef} />
                        <ul className="category-list list-group list-group-flush">
                            <span className="h4"> Podział wydatków na kategorie: </span>
                            {renderListItemsWithSubcategories(chartUserValueData)}
                        </ul>
                    </>
                ) : (
                    <h2>Brak danych</h2>
                )}
            </div>

            <div className="stats-container-right stats-container">     
                <h1>Wydatki z &nbsp;
                    <select id="month" value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}>
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>
                    <select id="year" value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}>
                        {Array.from({ length: new Date().getFullYear() - 2021 }, (_, i) => 2023 + i).map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select></h1>
               
                {monthlyChartRef && chartMonthlyValueData && userInfo ? (
                    <>
                    <div>
                        <Button onClick={toggleChartCategory}>{getToggleButtonText()}</Button>
                    </div>
                    <canvas ref={monthlyChartRef} />
                    <ul className="category-list list-group list-group-flush">
                        <span className="h4"> Podział wydatków na kategorie: </span>
                            {renderListItemsWithSubcategories(chartMonthlyValueData, true, false)}
                    </ul>
                    </>
                ) : (
                        <h2>Brak danych</h2>
                )}
                </div></>)
                :
                (<h1> Proszę się zalogować.</h1>)}
        </div>
    );
}
