import React from "react"
import store from './store';
import { Provider } from 'react-redux'
import ReactDOM from "react-dom/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import "./style/styles.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style/styles.css"
import "./style/main.css"
import "./style/css1.css" 

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>
    </Provider>
)