import React, { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import '../style/navbar.css';
import { useSelector} from 'react-redux';
import logo from '../img/bglesslogo.png';
import useLogoutUser from '../slices/logoutSlice';
import { useAuthorizeRequestMutation } from '../slices/usersApiSlice';
import { toast } from 'react-toastify';

export function CustomNav() {
    const [authorize] = useAuthorizeRequestMutation();
    const logoutUser = useLogoutUser();
    const { userInfo } = useSelector((state) => state.auth);
    const handleLogout = () => {
        logoutUser();
    };

    useEffect(() => {
        const logoutIfBadToken = async () => {
            if (userInfo && userInfo.token) {
                const token = userInfo.token;
                const res = await authorize({ token: token });
                console.log(res.data);
                if (res.data.status === 500 || res.data.status == undefined) {
                    toast.error('Błąd serwera');
                    return;
                }
                else if (!res.data.authorised) {
                    toast.info('Sesja wygasła: wylogowano');
                    logoutUser();
                }
            }
        }
        logoutIfBadToken();
    }, []);
    
    return (
        <>
            { }
            {['xl'].map((expand) => (
                <Navbar key='xl' expand='xl' className="navbar-rdg">
                    <Container fluid>
                        <Navbar.Brand href="/"><img
                            src={logo}
                            alt="SortSpend"
                            id="logo"
                        />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas 
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end">
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    SortSpend 
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link href="/dodaj-paragon">Dodaj paragon</Nav.Link>
                                    <Nav.Link href="/historia">Historia zakupów</Nav.Link>
                                    <Nav.Link href="/statystyki">Statystyki</Nav.Link>
                                    <Nav.Link href="/kontakt">Kontakt</Nav.Link>
                                    {userInfo ? (
                                        <NavDropdown className="login-dropdown" title={userInfo.email} id={`offcanvasNavbarDropdown-expand-${expand}`}>
                                            <NavDropdown.Item onClick={handleLogout}>Wyloguj</NavDropdown.Item>
                                        </NavDropdown>
                                    ) : (
                                        <Nav.Link href="/login">Zaloguj/Zarejestruj</Nav.Link>
                                    )}
                                </Nav>
                                
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                </Container>
            </Navbar>
            ))}
        </>
    );
}
