import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useVerifyMutation } from '../slices/usersApiSlice';
import { toast } from 'react-toastify';

export function EmailVerify(){
	const [validUrl, setValidUrl] = useState(true);
	const param = useParams();
	const [verify] = useVerifyMutation();

	useEffect(() => {
		const verifyEmailUrl = async () => {
			try {
				const res = await verify({ id: param.id, token: param.token }).unwrap();
				console.log(res);
				console.log(param.token);
				console.log(param.id);
				if (res.status == 200) {
					setValidUrl(true);
                }
				toast.notify(res.message);
			} catch (error) {
				setValidUrl(false);
			}
		};
		verifyEmailUrl();
	}, [param]);

	return (
		<div className="content">
			{validUrl ? (
				<h1>Pomyślnie zweryfikowano e-mail.</h1>
			) : (
				<h1>404 Not Found</h1>
			)}
		</div>
	);
};
