import React from 'react';
import { Route, Routes } from "react-router-dom"
import { Home } from "./pages/Home"
import { Contact } from "./pages/Contact"
import { AddReceipt } from "./pages/AddReceipt"
import { History } from "./pages/History"
import { NotFound } from "./pages/NotFound"
import { Login } from "./pages/Login"
import { Statistics2 } from "./pages/Statistics2"
import { EmailVerify } from "./pages/EmailVerify"
import { Statute } from "./pages/Statute"
import { CustomNav } from "./components/Nav"
import { Footer } from "./components/Footer"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function App() {
    return (
        <>
            <CustomNav />
            <ToastContainer />
            <div id="container">
            <div className="side-div side-left"></div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/historia" element={<History />} />
                <Route path="/dodaj-paragon" element={<AddReceipt />} />
                <Route path="/statystyki" element={<Statistics2 />} />
                <Route path="/login" element={<Login />} />
                <Route path="/users/:id/verify/:token" element={<EmailVerify />} />
                <Route path="/regulamin" element={<Statute />} />
                <Route path="/kontakt" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
             <div className="side-div side-right"></div>
            </div>
            <Footer />
        </>
    );
}

export default App;
