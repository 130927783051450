import React from 'react';
import { Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import '../style/home.css';
import logo from '../img/bglesslogo.png';
import historyico from '../img/history.png';
import phoneico from '../img/handphoneico.png';
import chartico from '../img/piechartico.png';
import galleryico from '../img/galleryico.png';

export function Home() {
    const customButtonStyle = {
        backgroundColor: '#1a3f51',
        border: 'none',
        marginTop: '15px',
    };

    return (       
        <div className="content">
            <div className="introduction">
                <img src={logo} alt="logo" className="home-logo"/>
                <p className="h2"> Czy ostatnio uciekają ci pieniądze? Nie wiesz na co i ile wydajesz? Może nie zwracasz uwagi na ceny produktów. SortSpend to aplikacja pomagająca w zarządzanmiu finansami domowymi. </p>
                <Nav.Link href="/dodaj-paragon" ><Button style={customButtonStyle}> Spróbuj sam </Button></Nav.Link>
            </div>


            <div className="panel-container row">   
                <div className="panel col-sm-5 mb-3">
                    <div className="panel-img-div">
                        <img src={phoneico} alt="logo" />
                    </div>
                    <p className="h3">
                        W domu, podróży lub tuż po zakupach. Dodaj swój paragon i oszczędzaj. Nie musisz nic pobierać.
                    </p>
                </div>

                <div className="panel col-sm-5 mb-3">
                    <div className="panel-img-div">
                        <img src={historyico} alt="logo" />
                    </div>
                    <p className="h3">
                        Przeglądaj swoją historię zakupów i porównaj ceny z przeszłości. 
                    </p>
                </div>

                
                <div className="panel col-sm-5 mb-3">
                    <div className="panel-img-div">
                        <img src={chartico} alt="logo" />
                    </div>
                    <p className="h3">
                        Zobacz na co najwięcej wydajesz, zmień swoje nawyki zakupowe i zacznij oszczędzać.
                    </p>
                </div>

                <div className="panel col-sm-5 mb-3">
                    <div className="panel-img-div">
                        <img src={galleryico} alt="logo" />
                    </div>
                    <p className="h3">
                        Dodaj z ręki lub prześlij zdjęcie paragonu, a nasza sztuczna inteligencja znajdzie i pogrupuje za ciebie produkty.
                    </p>
                </div>
            </div>
        </div>
    );
}
