import { apiSlice } from './apiSlice';
const USERS_URL = 'https://sortspend.pl/api/users';

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/login`,
                method: 'POST',
                body: data
            })
        }),
        logout: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/logout`,
                method: 'POST'
            })
        }),
        register: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/register`,
                method: 'POST',
                body: data
            })
        }),
       verify: builder.mutation({
            query: ({ id, token }) => ({
                url: `${USERS_URL}/${id}/verify/${token}`,
                method: 'GET', 
            }),
        }),
        authorizeRequest: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/authorize-request`,
                method: 'GET',
                headers:data
            }),
        })
    })
})

export const { useLoginMutation, useLogoutMutation, useRegisterMutation, useVerifyMutation, useAuthorizeRequestMutation} = usersApiSlice;
