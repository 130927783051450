import { Pie } from 'react-chartjs-2';
import { Modal, Button, ModalHeader, ModalBody } from 'react-bootstrap';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import '../style/addrec.css';
import { toast } from 'react-toastify';
import $ from 'jquery';
import  { categoriez } from '../slices/productCategories.js'

export function AddReceipt() {
    const [showModal, setShowModal] = useState(false);
    const [inputRows, setInputRows] = useState([{ id: 1 }]);
    const [totalCena, setTotalCena] = useState(0);
    const [nazwaSklepu, setNazwaSklepu] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const { userInfo } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [isManualMode, setIsManualMode] = useState(true);
    const [rowIndex, setRowIndex] = useState(2);


    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleRemoveRow = (rowId) => {
        if (inputRows.length === 1) {
            return;
        }
        console.log(inputRows);

        const updatedRows = inputRows.filter((row) => row.id !== rowId);
        setInputRows(updatedRows);

        const newTotalCena = updatedRows.reduce((sum, row) => sum + (parseFloat(row.cena) || 0), 0);
        setTotalCena(parseFloat(newTotalCena.toFixed(2)));
    };

    const handleAddInput = () => {
        const newId = rowIndex;
        setRowIndex(prevIndex => prevIndex + 1);

        const newRow = { id: newId };
        setInputRows([...inputRows, newRow]);
    };

    const handleTotalCenaChange = (event) => {
        setTotalCena(parseFloat(event.target.value));
    };

    const handleInputChange = (id, field, value) => {
        const updatedRows = inputRows.map((row) => {
            if (row.id === id) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setInputRows(updatedRows);
        
        const newTotalCena = updatedRows.reduce((sum, row) => sum + (parseFloat(row.cena) || 0), 0);
        setTotalCena(parseFloat(newTotalCena.toFixed(2)));
    };

    const calculateCategoryTotals = (data) => {
        const categoryTotals = {};
       
        if (data && data.products) {
            data.products.forEach((product) => {
                const { category, price } = product;
                if (categoryTotals[category]) {
                    categoryTotals[category] = parseFloat((categoryTotals[category] + price).toFixed(2));
                } else {
                    categoryTotals[category] = parseFloat(price.toFixed(2));
                }
            });
        }

        return categoryTotals;
    };

    const renderInputs = () => {
        return inputRows.map((row) => (
            <div className="four-inputs-container" key={row.id}>
                <div className="prod-col">
                    <input
                        type="text"
                        value={row.nazwa}
                        onChange={(event) => handleInputChange(row.id, 'nazwa', event.target.value)}
                        id={`nazwa-${row.id}`}
                        placeholder="nazwa"
                        className="new-receipt-input input"
                    />
                </div>
                <div className="qty-col">
                    <input
                        type="number"
                        value={row.ilosc}
                        placeholder="ilość"
                        onChange={(event) => handleInputChange(row.id, 'ilosc', event.target.value)}
                        id={`ilosc-${row.id}`}
                        className="new-receipt-input input"
                    />
                </div>
                <div className="cat-col">
                    <select
                        value={row.rodzaj}
                        onChange={(event) => handleInputChange(row.id, 'rodzaj', event.target.value)}
                        id={`rodzaj-${row.id}`}
                        className="new-receipt-input input"
                    >
                        <option value="" >
                            wybierz kategorię
                        </option>
                        {Object.keys(categoriez).map(subcategory => (
                            <option key={subcategory} value={subcategory}>{subcategory}</option>
                        ))}
                    </select>
               </div>
                <div className="price-col">
                    <input
                        type="number"
                        value={row.cena}
                        onChange={(event) => handleInputChange(row.id, 'cena', event.target.value)}
                        id={`cena-${row.id}`}
                        placeholder="cena"
                    className="new-receipt-input input"
                    />
                </div>
                    <div className="delbtn-col">
                        <Button className="" variant="danger" onClick={() => handleRemoveRow(row.id)}>
                            Usuń
                        </Button>
                    </div>
            </div>
        ));
    };

    const createReceipt = () => {
        if (userInfo && userInfo.token) {
            const token = userInfo.token;
            const paragon = {
                nazwa_sklepu: nazwaSklepu,
                data: new Date(),
                total: 0,
                products: []
            };

            let totalCena = 0;
            let hasErrors = false;

            inputRows.forEach((row) => {
                const ilosc = parseFloat(row.ilosc);
                const nazwa = row.nazwa;
                const rodzaj = row.rodzaj;
                const cena = parseFloat(row.cena);

                if ((!ilosc || !nazwa || !cena) && cena !=0) {
                    toast.error('Wszystkie pola są obowiązkowe.');
                    hasErrors = true;

                    $(`#ilosc-${row.id}`).addClass('error-field');
                    $(`#nazwa-${row.id}`).addClass('error-field');
                    $(`#rodzaj-${row.id}`).addClass('error-field');
                    $(`#cena-${row.id}`).addClass('error-field');
                    return;
                } else {
                    $(`#ilosc-${row.id}`).removeClass('error-field');
                    $(`#nazwa-${row.id}`).removeClass('error-field');
                    $(`#rodzaj-${row.id}`).removeClass('error-field');
                    $(`#cena-${row.id}`).removeClass('error-field');
                }
                
                if (!rodzaj || rodzaj == 0) {
                    toast.error('Wybierz kategorię');
                    
                    hasErrors = true;

                    $(`#rodzaj-${row.id}`).addClass('error-field');
                    return;
                } else {
                    $(`#rodzaj-${row.id}`).removeClass('error-field');
                }

                if (isNaN(ilosc) || ilosc <=0.0 ) {
                    toast.error('Ilość > 0.');
                    hasErrors = true;

                    $(`#ilosc-${row.id}`).addClass('error-field');
                    return;
                } else {
                    $(`#ilosc-${row.id}`).removeClass('error-field');
                }

                if (isNaN(cena) || cena <= 0) {
                    toast.error('Niepoprawna cena produktu.');
                    hasErrors = true;

                    $(`#cena-${row.id}`).addClass('error-field');
                    return;
                } else {
                    $(`#cena-${row.id}`).removeClass('error-field');
                }

                const produktObj = {
                    quantity: ilosc,
                    name: nazwa,
                    category: rodzaj,
                    price: cena
                };

                paragon.products.push(produktObj);
                totalCena += cena;
            });

            if (hasErrors) {
                return;
            }

            paragon.total = parseFloat(totalCena.toFixed(2));


            const categoryTotals = calculateCategoryTotals(paragon);

            const categoryTotalsWithMainCat = {};
            Object.keys(categoryTotals).forEach((category) => {
                if (categoriez[category] && categoriez[category].category) {
                    const mainCategory = categoriez[category].category;
                    const subCategory = category;

                    if (!categoryTotalsWithMainCat[subCategory]) {
                        categoryTotalsWithMainCat[subCategory] = {};
                    }

                    categoryTotalsWithMainCat[subCategory] = {
                        value: categoryTotals[category],
                        category: mainCategory,
                    };
                } else {
                    // Jeśli kategoria nie istnieje w categoriez, dodaj ją bez zmian
                    categoryTotalsWithMainCat[category] = {
                        value: categoryTotals[category],
                        category,
                    };
                }
            });

            axios.post('https://sortspend.pl/api/POST/paragon', {
                paragon,
                categoryTotalsWithMainCat,
            }, {
                headers: {
                    Authorization: token
                },
            })
                .then(response => {
                    setInputRows([{id:'', ilosc:0, nazwa:'', rodzaj:0, cena:'' }]);
                    setNazwaSklepu('');
                    setTotalCena(0);
                    setImageURL(null);
                    setImageFile(null);
                    toast.success('Pomyślnie dodano paragon.');
                })
                .catch(error => {
                    toast.warning('Wystąpił błąd podczas wysyłania danych ');
                });
        }
        else {
            toast.warning("Aby dodać paragon zaloguj się lub załóż konto");
        }
    };

    
    const analyzeImage = () => {
        if (!imageFile) {
            alert('Proszę wybrać zdjęcie paragonu.');
            return;
        }

        const formData = new FormData();
        formData.append('receiptImage', imageFile);
        setIsLoading(true);

        axios.post('https://sortspend.pl/api/POST/analyze-receipt', formData)
            .then(response => {
               const mappedProducts = response.data.products.map(product => ({
                    ...product,
                    category: Object.keys(categoriez)[product.category] || product.category, // Jeżeli nie znajdzie kategorii, użyj 'Inna kategoria'
                }));
                const mappedResponse = {
                    ...response.data,
                    products: mappedProducts,
                };
                setAnalyzedData(mappedResponse);
            })
            .catch(error => {
                console.error('Wystąpił błąd podczas analizy zdjęcia:', error);
            })
            .finally(() => {
                setIsLoading(false);
                setIsManualMode(true);
            });
    };

    const setAnalyzedData = (data) => {
        setTotalCena(data.total);
        setNazwaSklepu(data.shop);
        console.log(data);
        const newInputRows = data.products.map((product, index) => {
            const newRow = {
                id: index + 1,
                ilosc: product.quantity,
                nazwa: product.name,
                rodzaj: product.category,
                cena: product.price
            };
            return newRow;
        });
        setInputRows(newInputRows);  
    };

    const removeImage = () => {
        console.log(imageURL);
        setImageURL(null);
        setImageFile(null);
    };

    const handleImageChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            console.log(selectedFile);
            const imageUrl = URL.createObjectURL(selectedFile);
            setImageURL(imageUrl);
            setImageFile(selectedFile);
        }
    };

    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

    }, []);

    const handleDragIn = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    }, []);

    const handleDragOut = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const droppedFile = e.dataTransfer.files[0];
            const imageUrl = URL.createObjectURL(droppedFile);
            setImageURL(imageUrl);
            setImageFile(droppedFile);
        }
    }, []);

    function ReceiptModal({ show, handleClose, imageURL }) {
        return (
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <img src={imageURL} alt="Paragon" style={{ width: '100%', height: 'auto' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (

        <div className="content">
            <h1> Dodaj paragon </h1>
            <div className="add-rec-main-container">
                <div className="mode-buttons">
                    <button className={`mode-button btn-left ${isManualMode ? 'active' : ''}`} onClick={() => setIsManualMode(true)}> Dodaj recznie </button>
                    <button className={`mode-button btn-right ${!isManualMode ? 'active' : ''}`} onClick={() => setIsManualMode(false)}> Dodaj zdjęciem </button>
                </div>

                <div className={`new-receipt-container ${isLoading ? 'loading' : ''}`}>
                    {isManualMode ? (
                    <>  
                    <div className="top-new-receipt-info">
                        <input className="top-info-input"
                            type="text"
                            value={nazwaSklepu}
                            onChange={(event) => setNazwaSklepu(event.target.value)}
                            placeholder="Nazwa sklepu"
                        />
                        <label className="h3">Suma
                            <input  type="number" disabled value={totalCena} onChange={handleTotalCenaChange} className="total-sum-input top-info-input" />
                        </label>
                            </div>
                            <div className="add-rec-container">
                                <div className="side-image-container"
                                    style={{ display: imageURL ? 'block' : 'none' }}>
                                    <button onClick={openModal}>Paragon</button>
                                </div>

                                <div className="input-div">
                                    <div className="input-labels">
                                        <div className="prod-col">Produkt</div>
                                        <div className="qty-col">Ilość</div>
                                        <div className="cat-col">Kategoria</div>
                                        <div className="price-col">Cena</div>
                                        <div className="delbtn-col"></div>
                                    </div>
                                    <div>{renderInputs()}</div>
                                    <Button variant="secondary" className="add-new-line-btn" onClick={handleAddInput}>
                                        +
                                    </Button>
                                </div>

                            </div>
                    
                     
                    <div className="submitq-btn">
                        <Button variant="primary"  onClick={createReceipt}>
                            Zapisz paragon
                        </Button>
                    </div>
                </>
                ) : (
                    <div>
                        <p> Dodaj zdjęcie paragonu, a następnie kliknij przycisk "Analizuj zdjęcie". </p>
                        <div
                            className="drag-img-container"
                            style={{
                                border: dragging ? '2px solid #000' : '2px dashed #ccc',
                                
                            }}
                            onDrop={handleDrop}
                            onDragOver={handleDrag}
                            onDragEnter={handleDragIn}
                            onDragLeave={handleDragOut}
                        >
                            {imageURL ? (
                            <>
                                <button className="remove-image-button" onClick={removeImage}> X </button>
                                <img src={imageURL} className="recipe-img" alt="zdjęcie paragonu" />
                            </>
                            ) : (
                            <>
                            <p>Przeciągnij i upuść zdjęcie tutaj</p>
                            <input
                                type="file"
                                accept=".png, .jpg, .heic"
                                style={{
                                    opacity: 0,
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    cursor: 'pointer',
                                }}
                                onChange={handleImageChange}
                            />
                            </>
                            )}
                        </div>

                        <Button className="analyze-btn" onClick={analyzeImage}>Analizuj zdjęcie</Button>
                    </div>
                 )
                }
               
                </div>
            </div>

            <ReceiptModal show={showModal} handleClose={closeModal} imageURL={imageURL} />
        </div>
    );
}
