import React from 'react';
import { NavLink } from 'react-router-dom';
import '../style/footer.css'
import '../style/main.css'

export function Footer() {

    return (
        <footer id="footer" className="footer">
            <p>SortSpend &copy; 2024 &middot;
                <NavLink className="footer-link" to="/kontakt"> Kontakt </NavLink> &middot; <NavLink className="footer-link" to="/regulamin">Regulamin</NavLink>
            </p>
        </footer>

    );
}
