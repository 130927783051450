import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../style/login.css';
import { useNavigate } from "react-router-dom";
import { useLoginMutation, useRegisterMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';

export function Login() {
    const [isRegulationsAccepted, setIsRegulationsAccepted] = useState(false);
    const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
    const [isLoginMode, setIsLoginMode] = useState(true);
    const [loginData, setLoginData] = useState({ email: '', password: '' });
    const [ndPassword, setNdPassword] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [login, { isLoading }] = useLoginMutation();
    const [register, { isLoadingRegister }] = useRegisterMutation();
    const { userInfo } = useSelector((state) => state.auth);

    useEffect(() => {
        if (userInfo) {
            navigate('/');
        }
    }, [navigate, userInfo]);


    function isPasswordValid(password) {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
        return passwordRegex.test(password);
    }

    const switchToLogin = () => {
        setIsLoginMode(true);
        setLoginData({ email: '', password: '' });
    };

    const switchToRegister = () => {
        setIsLoginMode(false);
        setLoginData({ email: '', password: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    };

    const handleRememberMeChange = () => {
        setIsRememberMeChecked(!isRememberMeChecked);
    };

    const handleNdPasswordChange = (e) => {
        setNdPassword(e.target.value);
    };


    const handleRegulationsChange = () => {
        setIsRegulationsAccepted(!isRegulationsAccepted);
    };

    const  handleSubmit = async (e) => {
        e.preventDefault();
        //logowanie
        if (isLoginMode) {
            try {
                const rememberMe = isRememberMeChecked;
                const email = loginData.email;
                const password = loginData.password;
                console.log('logowanie');
                const res = await login({ email, password, rememberMe }).unwrap();
                toast.info(res.message);
                console.log(res);
                if (res.status == 201) {
                    console.log('res.auth');
                    dispatch(setCredentials({ ...res.auth }));
                    navigate('/');                
                }

            } catch (error) {
                console.log(error);
                toast.error(error?.data?.message || error.error);
            }
        }
        else {
            // rejestracja
            if (!isRegulationsAccepted) {
                toast.info('Proszę zaakceptować regulamin.');
                return;
            }

            if (ndPassword === loginData.password) {
                try {
                    var email = loginData.email;
                    var password = loginData.password;
                    await register({ email, password }).unwrap();
                    navigate('/');
                } catch (error) {
                    toast.error(error?.data?.message || error.error);
                };
            }
            else {
                toast.error("Hasła nie zgadzają się");
            }
        }
    };


    return (
        <div className={`content ${isLoginMode ? 'login-mode' : 'register-mode'}`}>
            <div className="form-container">
                <div className="mode-buttons">
                    <button
                        onClick={switchToLogin}
                        className={`mode-button ${isLoginMode ? 'active' : ''} btn-left`}
                    >
                        Zaloguj się
                    </button>
                    <button
                        onClick={switchToRegister}
                        className={`mode-button ${!isLoginMode ? 'active' : ''} btn-right`}
                    >
                        Załóż konto
                    </button>
                </div>

                <div className="form-div">
                    <form onSubmit={handleSubmit} className="login-form">
                        <h2>{isLoginMode ? 'Zaloguj się' : 'Załóż konto'}</h2>
                        <div className="input-container">
                            <input
                                type="email"
                                placeholder="adres e-mail"
                                id="login"
                                name="email"
                                value={loginData.email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="input-container">
                            <input
                                type="password"
                                placeholder="hasło"
                                id="password"
                                name="password"
                                value={loginData.password}
                                onChange={handleInputChange}
                            />
                        </div>

                        {!isLoginMode ? (
                            <div className="input-container">
                                <input
                                    type="password"
                                    placeholder="potwierdź hasło"
                                    id="ndpassword"
                                    name="ndpassword"
                                    value={ndPassword}
                                    onChange={handleNdPasswordChange}
                                />
                            </div>
                        ) : null}

                        {isLoginMode ? (
                            <div className="remember-me">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isRememberMeChecked}
                                        onChange={handleRememberMeChange}
                                    /> Zapamiętaj mnie
                                </label>
                            </div>
                        ) : (
                            <div className="accept-regulations">
                                <input
                                    type="checkbox"
                                    id="regulationsAcceptance"
                                    checked={isRegulationsAccepted}
                                    onChange={handleRegulationsChange}
                                />
                                <label htmlFor="regulationsAcceptance">
                                    Akceptuję <a href="/regulamin" target="_blank" rel="noopener noreferrer">regulamin</a> strony.
                                </label>
                            </div>
                        )}
                        {isLoading && <h2> Loguje... </h2>}
                        <button className="submit-btn" type="submit">
                            {isLoginMode ? 'Zaloguj się' : 'Zarejestruj się'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
