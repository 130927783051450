import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import optionsImage from '../img/options.svg';
import axios from 'axios';
import '../style/history.css';
import '../style/css1.css'
import { getCategoryName } from '../slices/productCategories';
import useLogoutUser from '../slices/logoutSlice';
import { toast } from 'react-toastify';

export function History() {
    const [items, setItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [initialItems, setInitialItems] = useState([]);
    const { userInfo } = useSelector((state) => state.auth);
    const logoutUser = useLogoutUser();

    useEffect(() => {
        if (userInfo && userInfo.token) {
            const token = userInfo.token;
            axios
                .get(`https://sortspend.pl/api/GET/all-bills`, {
                    headers: { Authorization: token} 
                })
                .then((response) => {
                    if (response.status == 200  && response.data.bills.length > 0) {

                        const initialItems = response.data.bills.map((bill) => ({
                            ...bill,
                            products: bill.receipt.products, 
                        }));
                        
                        setItems(initialItems);
                        setInitialItems(initialItems);
                    } else if (response.status == 401) {
                        console.log('not ok');
                        logoutUser();
                    } else {
                        toast.error(response?.data?.message || 'Błąd podczas odczytywania danych');
                    }
                })
                .catch((error) => {
                    toast.error(error.error);
                });
        }}, []);

    const toggleDetails = (itemId) => {
        setItems((prevItems) =>
            prevItems.map((item) => {
                if (item._id === itemId) {
                    return { ...item, isOpen: !item.isOpen, isSwapOpen: false };
                }
                return item;
            })
        );
    };


    const calculateTotalPrice = (itemsList) => {
        if (!itemsList || itemsList.length === 0) {
            return 0;
        }

        // Użyj metody reduce do zsumowania cen
        const totalPrice = itemsList.reduce((total, item) => {
            return total + item.price;
        }, 0);

        return totalPrice.toFixed(2);
    };

    const filterItems = (searchTerm, items) => {
        return items.filter((item) => {
            // Sprawdzamy, czy fraza występuje w produktach każdego elementu historii
            return item.products.some((product) =>
                product.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
    };

    const handleSearch = () => {
        if (searchTerm === '') {
            // Jeśli pole wyszukiwania jest puste, wyświetl całą historię
            setItems(initialItems);
        } else {
            // W przeciwnym razie przeprowadź filtrowanie
            const filteredItems = filterItems(searchTerm, items);
            setItems(filteredItems);
        }
    };

    const handleSearchKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch(); // Wywołaj funkcję handleSearch po naciśnięciu Enter
        }
    };

    const calculateTotal = (data) => {
        return data.reduce((total, value) => total + value, 0);
    };

    const formatPrice = (price) => {
        return price.toFixed(2);
    };

    const calculateCategoryTotals = (products) => {
        const categoryTotals = {};
        if (products) {
            products.forEach((product) => {
                const { category, price } = product;
                if (categoryTotals[category]) {
                    categoryTotals[category] += price;
                } else {
                    categoryTotals[category] = price;
                }
            });
        }

        return categoryTotals;
    };

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('pl-PL', options);
        return formattedDate;
    };

    return (
        <div className="history-content content">
            <h1>Historia zakupów</h1>
            <div className="search-bar">
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleSearchKeyPress}
                />
                <button className="search-button" onClick={handleSearch}>
                    🔍
                </button>
            </div>

            {Array.isArray(items) && items.length > 0 && userInfo ? (
                items.map((item) => (
                    <div className="history-container" key={item._id}>
                        <div className="purchase-item">
                            <div className="purchase-date">Data: {formatDate(item.receipt.data)}</div>
                            <div className="purchase-shop">Sklep: {item.receipt.shop}</div>
                            <div className="purchase-total">Wydatki: {item.receipt.total} zł</div>
                            <div onClick={() => toggleDetails(item._id)} className="purchase-options">
                                <div  className="options-ico">
                                    <a className="option-btn">
                                        <img src={optionsImage} alt="more" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {<hr className="history-hr" />}
                        <div
                            className={`details purchase-details ${item.isOpen ? 'open' : ''}`}
                            id={`purchase-details-${item._id}`}>
                            <div className="item-sort-div detail-div">
                                <h3>Lista produktów:</h3>
                                <div>
                                    {item.products.map((product, index) => (
                                        <p className="product-detail" key={index}>
                                            <span>{product.quantity} x </span>
                                            <span title={product.name}>&nbsp; {product.name.length > 10 ? product.name.slice(0, 27) + '...' : product.name}</span>
                                            <span> - {product.price} zł</span>
                                        </p>
                                    ))}
                                </div>
                            </div>

                            <div className="category-summary detail-div">
                                <h3> Kategorie:</h3>
                                <div>
                                    {Object.entries(calculateCategoryTotals(item.products)).map(([category, total]) => (
                                        <p className="product-detail" key={category}>
                                            <span>{category} - </span>
                                            <span>&nbsp; {total.toFixed(2)} zł</span>
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                ))
            ) : (
                <div>Brak danych do wyświetlenia.</div>
            )}
        </div>
    );
}
