import { useLogoutMutation } from '../slices/usersApiSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { logout } from '../slices/authSlice';
import { useDispatch } from 'react-redux';

const useLogoutUser = () => {
    const [logoutApiCall] = useLogoutMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutUser = async () => {
        try {
            await logoutApiCall().unwrap();
            dispatch(logout());
            toast.info('Wylogowano użytkownika.');
            navigate('/');
        } catch (error) {
            console.error(error);
        }
    };

    return logoutUser;
};

export default useLogoutUser;